import React, { useState, useEffect } from 'react'
import axios from 'axios'

const Content = props => {

  const skeletStyle = (i) => {
    return {
    width: '100%',
    height: 20,
    borderRadius: 4,
    marginBottom: i%5 === 0 ? 25: 10,
    opacity: 0.8,
    backgroundColor: '#eee'
    }
  }

  const [content, setContent] = useState([...Array(15)].map((e, i) => <div key={i} style={skeletStyle(i+1)}></div>));
  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    const fetchData = async () => {
      const result = await axios('/lazy_content/' + props.url)
      setContent(result.data)
      setLoaded(true)
      const elem = document.getElementById("content_holder");
      elem.parentNode.removeChild(elem);
    }
    fetchData();
  }, []);

  return loaded ? <div itemProp="articleBody" className="content" style={{minHeight: '500px'}} dangerouslySetInnerHTML={{__html: content}}></div> : content
}

export default Content
