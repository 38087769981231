require('shufflejs')
require("@fancyapps/fancybox")
require('jquery.cookie')
import Shuffle from 'shufflejs'

const prev_icon = require("../images/prev.svg")
const next_icon = require("../images/next.svg")

$(document).ready(function() {

  $('.cookies button').click(function(e){
    $.cookie('novosti_cookie_consented', 'true', { path: '/', expires: 365 });
    $('.cookies').fadeOut("slow");
  });

  $('.mobile-app button').click(function(e){
    $.cookie('novosti_mobile_cookie_closed', 'true', { path: '/', expires: 365 });
    $('.mobile-app').fadeOut("slow");
  });

  $('.playButton').on('click', function(){
    $("#gallery_starter").trigger('click');
  })

  $('[data-fancybox="gallery"]').fancybox({
  	infobar: true,
    toolbar: false,
    smallBtn: true,
    closeClickOutside: true,
    mobile: {
      closeClickOutside: true,
      clickSlide: function(current, event) {
        return "close";
      },
    },
    btnTpl: {
      arrowLeft:
        '<button data-fancybox-prev class="fancybox-button fancybox-button--arrow_left" title="{{PREV}}">' +
        '<div><img src="' + prev_icon + '" /></div>' +
        "</button>",
      arrowRight:
        '<button data-fancybox-next class="fancybox-button fancybox-button--arrow_right" title="{{NEXT}}">' +
        '<div><img src="' + next_icon + '" /></div>' +
        "</button>"
    },
    i18n: {
      en: {
        CLOSE: "Zatvori",
        NEXT: "Sljedeća",
        PREV: "Prethodna"
      }
    }
  });

  /* add article to favorites */
  $('body').on('click', '.add_to_favorites', function(e){
    e.preventDefault();

    const id = this.href.split('/')[4];
    if($.cookie('novosti_moje') === undefined){
      var savedArticles = [];
    } else {
      var savedArticles = JSON.parse($.cookie('novosti_moje'));
    }

    const index = savedArticles.indexOf(id);
    if (index > -1) {
      savedArticles.splice(index, 1);
      $(this).text('plus');
      $(this).removeClass('saved');
    } else {
      savedArticles.push(id);
      $(this).text('minus');
      $(this).addClass('saved');
    }

    $.cookie('novosti_moje',  JSON.stringify(savedArticles));

    if(window.location.href.indexOf("saved") != -1) {
      $(this).parent().parent().parent().fadeOut(300);

      const element = document.querySelector('.gridblock');
      const sizer = element.querySelector('.gridblock div.sizer');
      const shuffleInstance = new Shuffle(element, {
        itemSelector: 'article',
        sizer: sizer
      });
      setTimeout(function(){ shuffleInstance.filter(Shuffle.ALL_ITEMS); }, 350);
      if($.cookie('novosti_moje')=="[]") {
        location.reload();
      }
    }

  });

  let current_page = 1;
  let placeholder_counter = 0;
  $('.bottom.issues').on('click', '.read_more_issues', function(e){
    e.preventDefault();
    current_page += 1;
    $(this).hide();
    $.ajax({
      url: "/tiskano-izdanje?page=" + current_page,
      success: function(result){
        $(".bottom.issues").append(result);

        placeholder_counter += 1;
        if(placeholder_counter > 2) {
          placeholder_counter = 0;
        } else {
          $('.vertical.placeholder').hide();
        }

        if($(result).find('.downloadLink').length < 20) {
          $('.read_more').hide();
        }
      }
    });
  });

  /* footer adjustment */
  function adjustFooter() {
    var $widthMax = 970;
    var $widthMaxMax = 1260;
    var $widthMin = 640;
    if(window.matchMedia('(max-width: '+$widthMax+'px)').matches && window.matchMedia('(min-width: '+$widthMin+'px)').matches) {
      var $leftCol= $("footer div:nth-of-type(1)").outerHeight();
      var $marginTop = $leftCol + 20;
      $("footer div:nth-of-type(4)").css("margin-top", $marginTop);
      var $rightCol= $("footer div:nth-of-type(2)").outerHeight()+$("footer div:nth-of-type(3)").outerHeight();
      var $marginTop = $rightCol + 50;
      $("footer div:nth-of-type(5)").css("margin-top", $marginTop);
      $leftCol= $("footer div:nth-of-type(1)").outerHeight()+$("footer div:nth-of-type(4)").outerHeight()+20;
      $rightCol= $("footer div:nth-of-type(2)").outerHeight()+$("footer div:nth-of-type(3)").outerHeight()+$("footer div:nth-of-type(5)").outerHeight()+50;
      if($leftCol>$rightCol) {$("footer").css("height", $leftCol);}
      else {$("footer").css("height", $rightCol);}
    }
    else if(window.matchMedia('(max-width: '+$widthMaxMax+'px)').matches && window.matchMedia('(min-width: '+$widthMax+'px)').matches) {
      $leftCol= $("footer div:nth-of-type(1)").outerHeight();
      $rightCol= $("footer div:nth-of-type(2)").outerHeight()+$("footer div:nth-of-type(3)").outerHeight()+30;
      if($leftCol>$rightCol) {$("footer").css("height", $leftCol);}
      else {$("footer").css("height", $rightCol);}
      $("footer div:nth-of-type(4)").css("margin-top", 0);
      $("footer div:nth-of-type(5)").css("margin-top", 0);
    }
    else {
      if(window.matchMedia('(max-width: '+$widthMin+'px)').matches) {
        $("footer div:nth-of-type(4)").css("margin-top", 30);
        $("footer div:nth-of-type(5)").css("margin-top", 30);
      }
      else {
        $("footer div:nth-of-type(4)").css("margin-top", 0);
        $("footer div:nth-of-type(5)").css("margin-top", 0);
      }
      $("footer").css("height", "auto");
    }
  }
  adjustFooter();

  /* sale points */
  $("div.salepoint h4").on('click', function(e){
    $(this).parent().toggleClass("open");
  });

  // mobile menu
  var menuIsFunctional = false;
  function setMobileMenu() {
    if(window.matchMedia('(max-width: 970px)').matches) {
      $('header nav').addClass('mobile');
      if(!menuIsFunctional) {
        menuIsFunctional = true;
        // open-close mobile menu
        $('header nav.mobile span').on('click', function(e){
          e.preventDefault();
          $("body").toggleClass('open');
          if(!$("body").hasClass('open')) {
            shuffleInstance.filter();
          }
        });
        // expand-contract submenu
        $('header nav.mobile ul li.hasSub h6').on('click', function(e){
          e.preventDefault();
          $(this).parent().toggleClass("open")
        });
      }
    }
    else {
      $('header nav').removeClass('mobile');
    }
  }
  setMobileMenu();

  // single column header hack
  function columnHeader() {
    if(window.matchMedia('(max-width: 970px)').matches && $('main.single.column').length && $('main.single.column header img').length==0 && $('main.single.column header p').length==0) {
      var image = $('aside img').clone();
      var author = $('aside p').clone();
      var socialLinks = $('main.single.column header div.socialLinks');
      socialLinks.after(image);
      socialLinks.before(author);
    }
    else if (window.matchMedia('(min-width: 971px)').matches && $('main.single.column').length) {
      $('main.single.column header img').remove();
      $('main.single.column header p').remove();
    }
    if(window.matchMedia('(max-width: 970px)').matches && $('main.single.column').length) {
      var socialLinks = $('main.single.column header div.socialLinks');
      var image = $('main.single.column header img');
      var $padding = parseInt(socialLinks.css("padding-bottom"));
      var top= socialLinks.position().top+socialLinks.height()+$padding-image.height();
      image.css("top",top);
    }
  }
  columnHeader();

  // read more link on homepage and in categories
  function setReadMoreLink() {
    if($(".gridblock").length && window.matchMedia('(max-width: 640px)').matches) {
      $(".gridblock a.read_more").css("top",$(".gridblock").height()+150);
    }
  }
  setReadMoreLink();


  /* grid stuff - homepage and categories */
  if($(".gridblock").length) {

    var element = document.querySelector('.gridblock');
    var sizer = element.querySelector('.gridblock div.sizer');

    if(window.matchMedia('(min-width: 641px)').matches) {
      var shuffleInstance = new Shuffle(element, {
        itemSelector: 'article',
        sizer: sizer
      });
    }

    let current_page = 1;
    $('.read_more').on('click', function(e){
      e.preventDefault();
      const slug = $(this).attr("data-slug");
      current_page += 1;
      $.ajax({
        url: "/ajax_articles?slug=" + slug + "&page=" + current_page,
        success: function(result){
          element.insertAdjacentHTML('beforeend', result);

          if(window.matchMedia('(min-width: 641px)').matches) {
            var source = $('<div>' + result + '</div>');
            var itemsFromResponse = source.find("article").length;
            var allItemsInGrid = Array.from(element.children);
            var newItems = allItemsInGrid.slice(-itemsFromResponse);
            shuffleInstance.add(newItems);
            setTimeout(function(){shuffleInstance.filter();},300);
            setTimeout(function(){shuffleInstance.filter();},600);
            setTimeout(function(){shuffleInstance.filter();},900);
            setTimeout(function(){shuffleInstance.filter();},1200);
            setTimeout(function(){shuffleInstance.filter();},5000);
            setTimeout(function(){shuffleInstance.filter();},10000);
          }
          else {
            setTimeout(setReadMoreLink,300);
            setTimeout(setReadMoreLink,600);
            setTimeout(setReadMoreLink,900);
            setTimeout(setReadMoreLink,1200);
            setTimeout(setReadMoreLink,5000);
            setTimeout(setReadMoreLink,10000);
          }

          if($(result).find('.content').length != 30 && $(result).find('.content').length != 15 && slug != "homepage") {
            $('.read_more').hide();
          }
        }
      });
    });
  }

  /* window loaded events */
  $(window).on("load", function(){
    setMobileMenu();
    adjustFooter();
    columnHeader();
    setReadMoreLink();
  });

  /* window resize events */
  $(window).on('resize', function(){
    setMobileMenu();
    adjustFooter();
    columnHeader();
    setReadMoreLink();
  });
});
